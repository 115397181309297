exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-classic-shuffle-tsx": () => import("./../../../src/pages/ClassicShuffle.tsx" /* webpackChunkName: "component---src-pages-classic-shuffle-tsx" */),
  "component---src-pages-daily-shuffle-tsx": () => import("./../../../src/pages/DailyShuffle.tsx" /* webpackChunkName: "component---src-pages-daily-shuffle-tsx" */),
  "component---src-pages-images-open-graph-tsx": () => import("./../../../src/pages/images/OpenGraph.tsx" /* webpackChunkName: "component---src-pages-images-open-graph-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lite-shuffle-tsx": () => import("./../../../src/pages/LiteShuffle.tsx" /* webpackChunkName: "component---src-pages-lite-shuffle-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */)
}

